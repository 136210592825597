// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	redirectUri: 'http://localhost:4200/auth/callback',
	clientId: 'jq8UVpkG5fPlw3ddLHFkLM0pfwHZ9ohh',
	domain: 'alservizi.eu.auth0.com'
};
