import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private injector: Injector) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const router = this.injector.get(Router);
        const notificationService = this.injector.get(ToastrService);


        return next.handle(req).pipe(
            catchError(
                (error: any, caught: Observable<HttpEvent<any>>) => {
                    if (error.status === 401) {
                        router.navigate(['/auth/login']);
                        // if you've caught / handled the error, you don't
                        // want to rethrow it unless you also want
                        // downstream consumers to have to handle it as
                        // well.
                        return of(error);
                    }

                    if (error.status === 403) {
                        notificationService.error('non hai i permessi sufficienti per l\'operazione', 'Errore');
                    }

                    if (error.status === 400) {
                        notificationService.warning(error.error.message);
                    }

                    if (error instanceof HttpErrorResponse && (error.status === 500)) {
                        notificationService.error(error.error.message, error.error.error);
                    } else if (error.status >= 500) {
                        notificationService.error(error.message, 'Errore');
                    }

                    throw error;
                }
            ),
        );
    }
}
