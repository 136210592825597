// Anglar
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Layout Directives
// Services
import {
	ContentAnimateDirective,
	FirstLetterPipe,
	GetObjectPipe,
	HeaderDirective,
	JoinPipe,
	MenuDirective,
	OffcanvasDirective,
	SafePipe,
	ScrollTopDirective,
	SparklineChartDirective,
	StickyDirective,
	TabClickEventDirective,
	TimeElapsedPipe,
	ToggleDirective
} from './_base/layout';


import { BaseComponent } from './_base/crud/components/base/base.component';
import { BaseEditComponent } from './_base/crud/components/base-edit/base-edit.component';
import { BaseListComponent } from './_base/crud/components/base-list/base-list.component';
import {ApiService} from "./api.service";
import { CrudListComponent } from './_base/crud/components/crud-list/crud-list.component';
import {RouterModule} from "@angular/router";
import { CrudEditComponent } from './_base/crud/components/crud-edit/crud-edit.component';
import {FormsModule} from "@angular/forms";


@NgModule({
  imports: [CommonModule, RouterModule, FormsModule],
	declarations: [
		// directives
		ScrollTopDirective,
		HeaderDirective,
		OffcanvasDirective,
		ToggleDirective,
		MenuDirective,
		TabClickEventDirective,
		SparklineChartDirective,
		ContentAnimateDirective,
		StickyDirective,
		// pipes
		TimeElapsedPipe,
		JoinPipe,
		GetObjectPipe,
		SafePipe,
		FirstLetterPipe,
		CrudListComponent,
		CrudEditComponent,
	],
	exports: [
		// directives
		ScrollTopDirective,
		HeaderDirective,
		OffcanvasDirective,
		ToggleDirective,
		MenuDirective,
		TabClickEventDirective,
		SparklineChartDirective,
		ContentAnimateDirective,
		StickyDirective,
		// pipes
		TimeElapsedPipe,
		JoinPipe,
		GetObjectPipe,
		SafePipe,
		FirstLetterPipe,
		CrudListComponent,
		CrudEditComponent,
	],
	providers: [
		ApiService
	]
})
export class CoreModule {
}
