import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Location} from "../../../../../model/location";
import {ServiceType} from "../../../../../model/service-type";
import {SpotItem} from "../../../../../model/business-order";

@Component({
  selector: 'gt-spot-row',
  templateUrl: './spot-row.component.html',
  styleUrls: ['./spot-row.component.scss']
})
export class SpotRowComponent implements OnInit {

	@Input() spots: SpotItem[]
	@Input() locations$: BehaviorSubject<Location[]>
	@Input() serviceTypes$: BehaviorSubject<ServiceType[]>

  constructor() { }

  ngOnInit() {
  }

  addSpot() {
		this.spots.push(new SpotItem())
	}


	delete(spot: SpotItem) {
		let index = this.spots.indexOf(spot)
		this.spots.splice(index, 1)
	}
}
