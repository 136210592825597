import {Component, Inject, OnInit} from '@angular/core';
import {SelectionDidChange} from "../../../partials/content/planning/selection-toolbar/selection-toolbar.component";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {ExcelComponent} from "../excel/excel.component";
import {Pool} from "../../../../model/pool";
import {Operator} from "../../../../model/operator";

@Component({
  selector: 'gt-orders-report',
  templateUrl: './orders-report.component.html',
  styleUrls: ['./orders-report.component.scss']
})
export class OrdersReportComponent implements OnInit {

	selectedOperatorId: any;
	selectedPoolId: any;
	selectedDate: any;

	public pools: Pool[]
	public operators: Operator[]

  constructor(
		public dialogRef: MatDialogRef<ExcelComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.pools = data.pools
		this.operators = data.operators
	}

  ngOnInit() {
  }

	selectionDidChange($event: SelectionDidChange) {

	}
}
