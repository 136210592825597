export class DomainObject {
	id: number;
	createdAt: Date;
	updatedAt: Date;
	deleted: boolean;
	archived: boolean;
	validationErrors: string[] = [];
	title: string

	isEqual(object: DomainObject): boolean {
		return this.id === object.id;
	}

	static compareFn(op1: DomainObject, op2: DomainObject) {
		if (op1 === null || op2 === null) {
			return false;
		}
		return op1.id === op2.id;
	}

}
