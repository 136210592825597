import { Signboard } from './signboard'
import {DomainObject} from "../core/_base/crud/models/_domain.model";
import {Address} from "./address";

export class Store extends DomainObject {
    name: string
		signBoard: Signboard;
		signboardName: string;
		signboardId: number;
		address: Address;
		title: string;
}
