import {Pool} from "../model/pool";
import {RestService} from "../core/_base/crud/services/_rest.service";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class PoolService extends RestService<Pool> {

	constructor(http: HttpClient) {
		super(http, "pools");
	}

	defaultSortBy(): string {
		return "name";
	}

}
