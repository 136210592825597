import {_httpService} from "./_http.service";
import {HttpClient} from "@angular/common/http";

export class BackendService extends _httpService {

	protected base = '/api/';

	protected constructor(http: HttpClient, protected path: string) {
		super(http);
	}

	protected basepath() {
		return this.base + this.path;
	}

}
