export class MenuConfig {
	public defaults: any = {
		header: {
			self: {},
			items: [
			]
		},
		aside: {
			self: {},
			items: [
				{
					title: 'Dashboard',
					root: true,
					icon: 'flaticon2-architecture-and-city',
					page: '/dashboard',
					translate: 'MENU.DASHBOARD',
					bullet: 'dot',
				},
				{
					title: 'Excel',
					root: true,
					page: '/planning/excel',
					icon: 'flaticon2-expand',
				},
				{section: 'Pianificazione'},
				{
					title: 'Ordini di Servizio',
					icon: 'flaticon2-architecture-and-city',
					page: '/planning/service_orders'
				},
				{section: 'Commesse'},
				{
					title: 'Commesse',
					root: true,
					page: '/orders_registry/business_orders',
					icon: 'flaticon2-expand',
				},
				{
					title: 'Attività',
					root: true,
					page: '/orders_registry/activities',
					icon: 'flaticon2-expand',
				},
				{section: 'Anagrafiche'},
				{
					title: 'Anagragica Clienti',
					submenu: [
						{
							title: 'Ragioni sociali',
							icon: 'flaticon2-architecture-and-city',
							page: '/customers_registry/business_names'
						},
						{
							title: 'Insegne',
							icon: 'flaticon2-architecture-and-city',
							page: '/customers_registry/signboards'
						},
						{
							title: 'Punti vendita',
							icon: 'flaticon2-architecture-and-city',
							page: '/customers_registry/stores'
						},
						{
							title: 'Postazioni',
							icon: 'flaticon2-architecture-and-city',
							page: '/customers_registry/locations'
						},
						{
							title: 'Servizi',
							icon: 'flaticon2-architecture-and-city',
							page: '/customers_registry/service-types'
						},
					]
				},
				{
					title: 'Anagrafica Operatori',
					submenu: [
						{
							title: 'Operatori',
							icon: 'flaticon2-architecture-and-city',
							page: '/operators_registry/operators'
						},
						{
							title: 'Contratti',
							icon: 'flaticon2-architecture-and-city',
							page: '/operators_registry/contracts'
						},
						{
							title: 'Gruppi',
							icon: 'flaticon2-architecture-and-city',
							page: '/operators_registry/groups'
						},


					]
				}
			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
