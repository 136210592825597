import {Component, Input, OnInit} from '@angular/core';
import {ExcelDay} from "../../../../../../services/excel.service";

@Component({
  selector: 'gt-operator-day-cell',
  templateUrl: './operator-day-cell.component.html',
  styleUrls: ['./operator-day-cell.component.scss']
})
export class OperatorDayCellComponent implements OnInit {

	@Input() excelDay: ExcelDay

  constructor() { }

  ngOnInit() {
  }

}
