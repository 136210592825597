// Angular
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Components
import {BaseComponent} from './views/theme/base/base.component';
import {ErrorPageComponent} from './views/theme/content/error-page/error-page.component';
// Auth


const routes: Routes = [
	{path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)},

	{
		path: '',
		component: BaseComponent,
		canActivate: [],
		children: [
			{
				path: '',
				loadChildren: () => import('./views/pages/pages.module').then(m => m.PagesModule)
			},
			{
				path: 'dashboard',
				loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
			},
			{
				path: 'planning',
				loadChildren: () => import('./views/pages/planning/planning.module').then(m => m.PlanningModule),
			},
			{
				path: 'operators_registry',
				loadChildren: () => import('./views/pages/operators-registry/operators-registry.module').then(m => m.OperatorsRegistryModule)
			},
			{
				path: 'customers_registry',
				loadChildren: () => import('./views/pages/customers-registry/customers-registry.module').then(m => m.CustomersRegistryModule)
			},
			{
				path: 'orders_registry',
				loadChildren: () => import('./views/pages/orders/orders.module').then(m => m.OrdersModule)
			},
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					type: 'error-v6',
					code: 403,
					title: '403... Access forbidden',
					desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator',
				},
			},
			{path: 'error/:type', component: ErrorPageComponent},
			{path: '', redirectTo: 'dashboard', pathMatch: 'full'},
			{path: '**', redirectTo: 'dashboard', pathMatch: 'full'},
		],
	},

	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
