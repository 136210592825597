import {Component, Input, OnInit} from '@angular/core';
import {DayOfWeek, BusinessOrderRow, WeekDay, WeekDayRow, WeeklyItem} from "../../../../../model/business-order";
import {BehaviorSubject} from "rxjs";
import {ServiceType} from "../../../../../model/service-type";
import {Location} from "../../../../../model/location";
import {BaseComponent} from "../../../../../core/_base/crud/components/base/base.component";

@Component({
  selector: 'gt-weekly-planning',
  templateUrl: './weekly-planning.component.html',
  styleUrls: ['./weekly-planning.component.scss']
})
export class WeeklyPlanningComponent extends BaseComponent {

	@Input() weeklyItem: WeeklyItem
	@Input() locations$: BehaviorSubject<Location[]>
	@Input() serviceTypes$: BehaviorSubject<ServiceType[]>

	public rowsToCopy: WeekDayRow[] = []

	addWeekDayRow(weekDay: WeekDay) {
		if(!weekDay.weekDayRows) {
			weekDay.weekDayRows = []
		}
		weekDay.weekDayRows.push(new WeekDayRow())
	}


	delete(row: WeekDayRow, weekDay: WeekDay) {
		let index = weekDay.weekDayRows.indexOf(row)
		weekDay.weekDayRows.splice(index, 1)
	}

	onInit() {
	}

	copy(row: WeekDayRow) {
		this.rowsToCopy = []
		this.rowsToCopy.push(row)
	}

	copyAll(weekDay: WeekDay) {
		this.rowsToCopy = []
		for (let weekDayRow of weekDay.weekDayRows) {
			weekDayRow.id = undefined
			this.rowsToCopy.push(weekDayRow);
		}
	}

	paste(weekDay: WeekDay) {
		for (let weekDayRow of this.rowsToCopy) {
			weekDayRow.id = undefined
			weekDay.weekDayRows.push(weekDayRow);
		}
	}
}
