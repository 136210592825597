import { Component, OnInit } from '@angular/core';
import {BaseComponent} from "../../../../core/_base/crud/components/base/base.component";
import {BehaviorSubject, forkJoin} from "rxjs";
import {Operator} from "../../../../model/operator";
import {Pool} from "../../../../model/pool";
import {OperatorService} from "../../../../services/operator.service";
import {PoolService} from "../../../../services/pool.service";
import {QueryParamsModel} from "../../../../core/_base/crud";
import {EnumsService} from "../../../../services/enums.service";
import {ExcelService, ExcelView} from "../../../../services/excel.service";
import {SelectionDidChange} from "../../../partials/content/planning/selection-toolbar/selection-toolbar.component";
import {ShiftService} from "../../../../services/shift.service";
import {MatDialog} from "@angular/material";
import {OrdersReportComponent} from "../orders-report/orders-report.component";

@Component({
  selector: 'gt-excel',
  templateUrl: './excel.component.html',
  styleUrls: ['./excel.component.scss']
})
export class ExcelComponent extends BaseComponent {

	public operators$ = new BehaviorSubject<Operator[]>([])
	public pools$ = new BehaviorSubject<Pool[]>([])
	public daysOfWeek$ = new BehaviorSubject<string[]>([])
	public excelView$ = new BehaviorSubject<ExcelView>(null)



	selectedOperatorId: number = -1;
	selectedPoolId: number = -1;
	selectedDate: Date = new Date();

	constructor(private operatorService: OperatorService,
							private poolService: PoolService,
							private enumService: EnumsService,
							private excelService: ExcelService,
							private shiftService: ShiftService,
							private dialog: MatDialog
	) {
		super();
	}

	reload() {
		let getExcelView = this.excelService.excelForDate(this.selectedDate, this.selectedOperatorId, this.selectedPoolId).subscribe(excelView => {
			this.excelView$.next(excelView)
		})
	}

	onInit() {

		let findPools = this.poolService.findAll(QueryParamsModel.maxPageSize())
		let findOperators = this.operatorService.findAll(QueryParamsModel.maxPageSize())
		let findDaysOfWeek = this.enumService.findAllDayOfWeeks()
		let getExcelView = this.excelService.excelForDate(this.selectedDate, this.selectedOperatorId, this.selectedPoolId)

		forkJoin([findPools, findOperators, findDaysOfWeek, getExcelView]).subscribe(
			([pools, operators, daysOfWeek, excelView]) => {
				this.pools$.next(pools.content)
				this.operators$.next(operators.content)
				this.daysOfWeek$.next(daysOfWeek)
				this.excelView$.next(excelView)
			}
		)

	}

	selectionDidChange($event: SelectionDidChange) {
		this.selectedOperatorId = $event.selectedOperatorId
		this.selectedDate = $event.selectedDate
		this.selectedPoolId = $event.selectedPoolId

		this.reload()

	}

	save() {

		let shifts = []

		let excelView = this.excelView$.getValue()
		for(let i=0; i<excelView.excelRows.length; i++) {
			let excelRow = excelView.excelRows[i]
			for(let j=0; j<excelRow.days.length; j++) {
				let day = excelRow.days[j]
				for (let shift of day.shifts) {
					shifts.push(shift)
				}
			}
		}

		console.log(shifts)

		this.shiftService.createAll(shifts).subscribe( _ => {
			this.reload()
		})


	}

	ordersReport() {
		const dialogRef = this.dialog.open(OrdersReportComponent, {
			width: '98%',
			height: '98%',
			maxWidth: '95vw',
			maxHeight: '95vh',
			panelClass: 'full-screen-modal',
			data: {
				operators: this.operators$.getValue(),
				pools: this.operators$.getValue()
			}
		})
	}
}
