import {Observable} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {DomainObject} from "../models/_domain.model";
import {QueryParamsModel, QueryResultsModel} from "..";
import {BackendService} from "./_backend.service";

export abstract class RestService<T extends DomainObject> extends BackendService {

	abstract defaultSortBy(): string;

	// TODO, FIXME: WRONG OBSERVABLE TYPE RETURNED
	findAllLight(queryParams?: QueryParamsModel): Observable<QueryResultsModel<T>> {
		return this.findAll(queryParams, true);
	}

	findAll(queryParams?: QueryParamsModel, light = false): Observable<QueryResultsModel<T>> {

		var params = new HttpParams();

		// TODO: map pageable

		// add filters

		if (queryParams !== undefined) {

			for (let key in queryParams.filter) {
				let value = queryParams.filter[key];
				params = params.set(key, value);
			}

			params = params.set('page', queryParams.pageNumber.toString());
			params = params.set('size', queryParams.pageSize.toString());

		}

		let defaultSortBy = this.defaultSortBy();

		if (queryParams) {
			if (!queryParams.sortField && defaultSortBy !== null) {
				params = params.set('sort', defaultSortBy);
			} else if (queryParams.sortField) {
				params = params.set('sort', queryParams.sortField + ',' + queryParams.sortOrder);
			}
		} else {
			if (defaultSortBy !== null) {
				params = params.set('sort', defaultSortBy);
			}
		}


		let basepath = this.basepath();

		if (light) {
			basepath = basepath + '/light';
		}
		return this.http.get<QueryResultsModel<T>>(basepath, {params: params});
	}

	deleteOne(object: T): Observable<any> {
		return this.http.delete(this.basepath() + '/' + object.id);
	}

	create(object: T): Observable<T[]> {
		return this.http.post<T[]>(this.basepath(), [object]);
	}

	createAll(objects: T[]): Observable<T[]> {
		return this.http.post<T[]>(this.basepath(), objects);
	}

	findOne(id: number): Observable<T> {
		return this.http.get<T>(this.basepath() + '/' + id);
	}

	generatePdf(id: number): Observable<Blob> {
		// @ts-ignore
		return this.http.get<Blob>(this.basepath() + '/' + id + '/pdf', {responseType: 'blob'});
	}

	betweenDatesPathVariable(datepipe: DatePipe, startDate: Date, endDate: Date): string {
		const from_str = datepipe.transform(startDate, 'yyyy-MM-dd');
		const to_str = datepipe.transform(endDate, 'yyyy-MM-dd');

		return from_str + '/' + to_str;
	}

	duplicate(id: number): Observable<T> {
		return this.http.post<T>(this.basepath() + '/' + id + '/duplicate', {});
	}

	archive(object: T): Observable<T> {
		return this.archiveOne(object.id);
	}

	archiveOne(id: number): Observable<T> {
		return this.http.post<T>(this.basepath() + '/' + id + '/archive', {});
	}

	unArchive(object: T): Observable<T> {
		return this.http.post<T>(this.basepath() + '/' + object.id + '/unarchive', {});
	}

}


