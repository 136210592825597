import {Component, ContentChild, Input, OnInit, TemplateRef} from '@angular/core';

@Component({
  selector: 'gt-crud-edit',
  templateUrl: './crud-edit.component.html',
  styleUrls: ['./crud-edit.component.scss']
})
export class CrudEditComponent implements OnInit {

	@Input() object: any
	@Input() children: any

	@ContentChild('fields', {static: false}) fieldsTemplateRef: TemplateRef<any>;
	@ContentChild('additional', {static: false}) additionalTemplateRef: TemplateRef<any>

	ngOnInit(): void {
	}


	save() {
		this.children.save()
	}
}
