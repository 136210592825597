import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthenticationService} from "../../../../core/auth/authentication.service";
import {Authorization, OAUTHService} from "../../../../services/oauth.service";

@Component({
    selector: 'm-callback',
    templateUrl: './callback.component.html',
    styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {


    constructor(private activatedRoute: ActivatedRoute, private oauthService: OAUTHService, private authenticationService: AuthenticationService) {
    }

    ngOnInit() {
        // Note: Below 'queryParams' can be replaced with 'params' depending on your requirements
        this.activatedRoute.queryParams.subscribe(params => {
            const authorization = new Authorization(params['code'], window.location.href);
            this.oauthService.requestToken(authorization).subscribe(tokenHolder => this.authenticationService.handleAuthentication(tokenHolder));
        });
    }
}
