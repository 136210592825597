import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

@Injectable()
export class TokenStorage {

	kACCESS_TOKEN = 'access_token';
	kREFRESH_TOKEN = 'refresh_token';
	kID_TOKEN = 'id_token';
	kROLES = 'userRoles';
    kExpiration = 'expiresAt';
	kName = 'name';

	/**
	 * Get access token
	 * @returns {Observable<string>}
	 */
	public getAccessToken(): Observable<string> {
		const token: string = <string>localStorage.getItem(this.kACCESS_TOKEN);
		return of(token);
	}

	/**
	 * Get refresh token
	 * @returns {Observable<string>}
	 */
	public getRefreshToken(): Observable<string> {
		const token: string = <string>localStorage.getItem(this.kREFRESH_TOKEN);
		return of(token);
	}

    /**
	 * Get id token
     */
	public getIdToken(): Observable<string> {
		const token: string = <string>localStorage.getItem(this.kID_TOKEN);
		return of(token);
	}

	/**
	 * Get user roles in JSON string
	 * @returns {Observable<any>}
	 */
	public getUserRoles(): Observable<any> {
		const roles: any = localStorage.getItem(this.kROLES);
		try {
			return of(JSON.parse(roles));
		} catch (e) {}
	}

    public getExpiration(): Observable<number> {
        const expiration: string = localStorage.getItem(this.kExpiration);
        try {
            return of(JSON.parse(expiration));
        } catch (e) {
        }
    }

	public getUserName(): Observable<string> {
		return of(localStorage.getItem(this.kName));
	}

	/**
	 * Set access token
	 * @returns {TokenStorage}
	 */
	public setAccessToken(token: string): TokenStorage {
		localStorage.setItem(this.kACCESS_TOKEN, token);

		return this;
	}

	/**
	 * Set refresh token
	 * @returns {TokenStorage}
	 */
	public setRefreshToken(token: string): TokenStorage {
		localStorage.setItem(this.kREFRESH_TOKEN, token);

		return this;
	}
	
	public setIdToken(token: string): TokenStorage {
		localStorage.setItem(this.kID_TOKEN, token);
		return this;
	}

	/**
	 * Set user roles
	 * @param roles
	 * @returns {TokenStorage}
	 */
	public setUserRoles(roles: any): any {
		if (roles != null) {
			localStorage.setItem(this.kROLES, JSON.stringify(roles));
		}

        return this;
    }

    public setExpiration(expiration: number): any {
        localStorage.setItem(this.kExpiration, JSON.stringify(expiration));
        return this;
    }

	public setUserName(name: string): any {
		localStorage.setItem(this.kName, name);
		return this;
	}

	/**
	 * Remove tokens
	 */
	public clear() {
		localStorage.removeItem(this.kID_TOKEN);
		localStorage.removeItem(this.kACCESS_TOKEN);
		localStorage.removeItem(this.kREFRESH_TOKEN);
		localStorage.removeItem(this.kROLES);
		localStorage.removeItem(this.kName);
	}
}
