import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';

@Injectable()
export class HttpAddAuthorizationHeaderInterceptor implements HttpInterceptor {

  constructor(private injector: Injector) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url === 'api/login' || req.url.indexOf('oauth/') !== -1) {
        return next.handle(req);
    }

    const authService = this.injector.get(AuthService);
    const request = req.clone({headers: req.headers.set('Authorization', 'Bearer ' + authService.getToken())});
    return next.handle(request);
  }

}
