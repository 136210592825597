import {Component, ContentChild, Input, OnInit, TemplateRef} from '@angular/core';
import {DomainObject} from "../../models/_domain.model";
import {BaseListComponent} from "../base-list/base-list.component";

@Component({
  selector: 'gt-crud-list',
  templateUrl: './crud-list.component.html',
  styleUrls: ['./crud-list.component.scss']
})
export class CrudListComponent implements OnInit {

	@Input() objects: any
	@Input() children: any
	@ContentChild('item', {static: false}) itemTemplateRef: TemplateRef<any>;
	@ContentChild('emptyMessage', {static: false}) emptyMessageRef: TemplateRef<any>;

  constructor() { }

  ngOnInit() {
  }

  createNew() {
		this.children.createNew();
	}

	edit(object: any) {
		this.children.edit(object)
	}

	delete(object: any) {
  	this.children.delete(object)
	}

}
