import {Injectable} from "@angular/core";
import {BackendService} from "../core/_base/crud/services/_backend.service";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class EnumsService extends BackendService {


	constructor(http: HttpClient) {
		super(http, 'enums/');
	}

	findAllDayOfWeeks(): Observable<string[]> {
		return this.http.get<string[]>(this.basepath() + 'days-of-week');
	}


}
