import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {PartialsModule} from "../../partials/partials.module";
import {CoreModule} from "../../../core/core.module";
import {RouterModule} from "@angular/router";
import {ExcelComponent} from "./excel/excel.component";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import { ServiceOrdersListComponent } from './service-orders/service-orders-list/service-orders-list.component';
import {PoolService} from "../../../services/pool.service";
import {OperatorService} from "../../../services/operator.service";
import {EnumsService} from "../../../services/enums.service";
import {ExcelService} from "../../../services/excel.service";
import {ShiftService} from "../../../services/shift.service";
import { OrdersReportComponent } from './orders-report/orders-report.component';

@NgModule({
	imports: [
		CommonModule,
		PartialsModule,
		CoreModule,
		RouterModule.forChild([
			{
				path: 'excel',
				component: ExcelComponent
			},
			{
				path: 'service_orders',
				component: ServiceOrdersListComponent
			},
		]),
		MatButtonModule,
		MatTooltipModule,
		MatFormFieldModule,
		MatInputModule,
		MatDatepickerModule,
		MatAutocompleteModule,
		ReactiveFormsModule,
		MatIconModule,
		FormsModule,
	],
	providers: [
		PoolService, OperatorService, EnumsService, ExcelService, ShiftService
	],
	declarations: [
		ExcelComponent,
		ServiceOrdersListComponent,
		OrdersReportComponent,
	],
	entryComponents: [
		OrdersReportComponent
	]
})

export class PlanningModule {}
