export class PageConfig {
	public defaults: any = {
		dashboard: {
			page: {
				title: 'Dashboard',
				desc: 'Latest updates and statistic charts'
			},
		},
		planning: {
			excel: {
				page: {
					title: 'Excel',
				},
			},
			service_orders: {
				page: {
					title: 'Ordini di Servizio',
				},
			}
		},
		orders_registry: {
			business_orders: {
				page: {
					title: 'Commesse'
				}
			}
		},
		customers_registry: {
			business_names: {
				page: {
					title: 'Ragioni sociali',
				}
			},
			signboards: {
				page: {
					title: 'Insegne',
				}
			},
			stores: {
				page: {
					title: 'Punti Vendita',
				}
			},
			locations: {
				page: {
					title: 'Postazioni',
				}
			}
		},
		operators_registry: {
			operators: {
				page: {
					title: 'Operatori',
				}
			},
			contracts: {
				page: {
					title: 'Contratti',
				}
			},
			groups: {
				page: {
					title: 'Gruppi',
				}
			}
		},
		header: {
			actions: {
				page: {title: 'Actions', desc: 'Actions example page'}
			}
		},
		profile: {
			page: {title: 'User Profile', desc: ''}
		},
		error: {
			404: {
				page: {title: '404 Not Found', desc: '', subheader: false}
			},
			403: {
				page: {title: '403 Access Forbidden', desc: '', subheader: false}
			}
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
