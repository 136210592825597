import {RestService} from "../core/_base/crud/services/_rest.service";
import {Injectable} from "@angular/core";
import {Shift} from "../model/shift";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class ShiftService extends RestService<Shift> {

	constructor(http: HttpClient) {
		super(http, 'shifts');
	}

	defaultSortBy(): string {
		return "";
	}

}
