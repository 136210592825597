import {RestService} from "../core/_base/crud/services/_rest.service";
import {Operator} from "../model/operator";
import {HttpClient} from "@angular/common/http";

export class OperatorService extends RestService<Operator> {

	constructor(http: HttpClient) {
		super(http, "operators");
	}

	defaultSortBy(): string {
		return "lastName";
	}

}
