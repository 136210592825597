import { Injectable } from '@angular/core';
import { BusinessName } from '../model/business-name';
import { BusinessNameEditComponent } from '../views/pages/customers-registry/business-name/business-name-edit/business-name-edit.component';
import { Signboard } from '../model/signboard';
import { Location } from '../model/location';
import { Store } from '../model/store';
import { BusinessOrder } from '../model/business-order';


let replacer = function(k,v) {
  if (k.startsWith("_"))
    return []
  return v
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private businessNames:BusinessName[] = []
  private signboards:Signboard[] = []
  private locations:Location[] = []
  private stores:Store[] = []
  private orders:BusinessOrder[] = []

  constructor() {
    this.restore()
  }

  // fake x mockup
  private restore() {
    this.businessNames = window.localStorage.getItem("businessNames") ? JSON.parse(window.localStorage.getItem("businessNames")) : []
    this.signboards = window.localStorage.getItem("signboards") ? JSON.parse(window.localStorage.getItem("signboards")) : []
    this.stores = window.localStorage.getItem("stores") ? JSON.parse(window.localStorage.getItem("stores")) : []
    this.locations = window.localStorage.getItem("locations") ? JSON.parse(window.localStorage.getItem("locations")) : []
    this.orders = window.localStorage.getItem("orders") ? JSON.parse(window.localStorage.getItem("orders")) : []


    const slots = ["orders","businessNames", "signboards", "stores", "locations"]
    // resolve id to ref
    slots.forEach(s => {
      // for each object in current collection
      this[s].filter(x => x.id).forEach(async element => {
        // search _ids prop
        for (const prop in element) {
          if (prop.endsWith("_id")) {
            var resolved = "_" + prop.slice(0,-3)
            let target = this[prop.slice(0,-3) + "s"]
            element[resolved] = target.find(x=> element[prop] == x.id)
          }
          if (prop.endsWith("_ids")) {
            var resolved = "_" + prop.slice(0,-4)
            let targetList = this[prop.slice(0,-4)]
            if (!element[prop])
              element[prop] = []
            element[resolved] = element[prop].filter(x => x).map(x => {
              return targetList.find(y => x == y.id)
            })
          }
        }
      })
    })
  }

  // fake x mockup
  save() {
    const slots = ["orders","businessNames", "signboards", "stores", "locations"]

    // update ref to ids
    slots.forEach(s => {
      this[s].forEach(element => {
        for (const prop in element) {
          if (prop.startsWith("_") && !Array.isArray(element[prop])) {
            var id = prop.slice(1) + "_id"
            element[id] = element[prop] ? element[prop].id : null
          }

          if (prop.startsWith("_") && Array.isArray(element[prop])) {
            var id = prop.slice(1) + "_ids"
            element[id] = element[prop].map(x => x.id)
          }
        }
      });

      let b = JSON.stringify(this[s],replacer)
      console.log("persisting ", b)
      window.localStorage.setItem(s,b)
    })
  }

  /* BUSINESS NAMES */
  getBusinessNames():Promise<BusinessName[]> {
    return Promise.resolve(this.businessNames)
  }

  async getBusinessName(id: string):Promise<BusinessName> {
    // @ts-ignore
		let b = this.businessNames.find(b => b.id == id)
    if (b) {
      b.signboards_ids = b.signboards_ids ? b.signboards_ids : []
      return b
    }
  }

  newBusinessName(): BusinessName {
    let n = new BusinessName()
    // @ts-ignore
		n.id = (Math.random()).toString()
    n.name = "Nuovo"
    this.businessNames.push(n)
    return n
  }

  /* SIGNBOARDS */
  getSignboards():Promise<Signboard[]> {
    return Promise.resolve(this.signboards)
  }

  getSignboard(id:string):Promise<Signboard> {
    return new Promise(async (res) => {
      // @ts-ignore
			let s = this.signboards.find(b => b.id == id)
      res(s)
    })
  }

  newSignboard(): Signboard {
    let n = new Signboard()
    // @ts-ignore
		n.id = (Math.random() *100).toString()
    n.name = "Nuovo"
    this.signboards.push(n)
    return n
  }

   /* STORES */
   getStores():Promise<Store[]> {
    return Promise.resolve(this.stores)
  }

  getStore(id:string):Promise<Store> {
    // @ts-ignore
		return Promise.resolve(this.stores.find(b => b.id == id))
  }

  newStore(): Store {
    let n = new Store()
    // @ts-ignore
		n.id = (Math.random()).toString()
    n.name = "Nuovo"
    this.stores.push(n)
    return n
  }

  /* LOCATIONS */
  getLocations():Promise<Location[]> {
    return Promise.resolve(this.locations)
  }

  getLocation(id:string):Promise<Location> {
    // @ts-ignore
		return Promise.resolve(this.locations.find(b => b.id == id))
  }

  newLocation(): Location {
    let n = new Location()
    // @ts-ignore
		n.id = (Math.random()).toString()
    n.name = "Nuovo"
    this.locations.push(n)
    return n
  }


  /* ORDERS */
  getOrders():Promise<BusinessOrder[]> {
    return Promise.resolve(this.orders)
  }

  getOrder(id:string):Promise<BusinessOrder> {
    // @ts-ignore
		return Promise.resolve(this.orders.find(b => b.id == id))
  }

  newOrder(): BusinessOrder {
    let n = new BusinessOrder()
    // @ts-ignore
		n.id = (Math.random()).toString()
    n.name = "Nuovo"
    this.orders.push(n)
    return n
  }




}
