import {DomainObject} from "../_domain.model";

export class QueryResultsModel<T extends DomainObject> {
	// fields
	content: T[];
	totalCount: number;
	errorMessage: string;

	constructor(_items: any[] = [], _totalCount: number = 0, _errorMessage: string = '') {
		this.content = _items;
		this.totalCount = _totalCount;
	}
}
