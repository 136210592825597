import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gt-service-orders-list',
  templateUrl: './service-orders-list.component.html',
  styleUrls: ['./service-orders-list.component.scss']
})
export class ServiceOrdersListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
