import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import * as auth0 from 'auth0-js';
import {BehaviorSubject} from 'rxjs';
import {environment} from "../../../environments/environment";

@Injectable()
export class AuthService {

    auth0 = new auth0.WebAuth({
        clientID: environment.clientId,
        domain: environment.domain,
        responseType: 'code',
        audience: 'https://gt-api.alsecurityservizi.it/v1',
        redirectUri: environment.redirectUri,
        scope: 'openid profile gt:access',
        prompt: false
    });

    userProfile$: BehaviorSubject<any> = new BehaviorSubject<any>({});

    constructor(private router: Router) {
    }

    public login(): void {
        this.auth0.authorize();
    }

    public handleAuthentication(): void {
        this.auth0.parseHash((err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                window.location.hash = '';
                this.setSession(authResult);
                this.getProfile();
                this.router.navigate(['/dashboard']);
            } else if (err) {
                switch (err['errorDescription']) {
                    case 'PASSWORD-EXPIRED':
                        alert('La tua password per l\'accesso al sistema è scaduta: vai alla' +
                            'pagina di login e clicca su password dimenticata.');
                        break;
                    default:
                        this.router.navigate(['/auth/login']);
                        break;
                }
            }
        });
    }

    private setSession(authResult): void {
        // Set the time that the Access Token will expire at
        const expiresAt = JSON.stringify((authResult.expiresAt * 1000) + new Date().getTime());
        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('id_token', authResult.idToken);
        localStorage.setItem('expires_at', expiresAt);
    }

    public getToken(): string {
        return localStorage.getItem('access_token');
    }

    public logout(): void {
        // Remove toNPMkens and expiry time from localStorage
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');

        window.location.href = 'https://' + environment.domain + '/v2/logout?returnTo='
            + environment.redirectUri + '&client_id=' + environment.clientId;

    }

    public isAuthenticated(): boolean {
        // Check whether the current time is past the
        // Access Token's expiry time
        const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
        return new Date().getTime() < expiresAt;
    }

    public getProfile(): void {

        if (this.isAuthenticated() === false) {
            return;
        }

        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
            throw new Error('Access token must exist to fetch profile');
        }

        const self = this;
        this.auth0.client.userInfo(accessToken, (err, profile) => {
            if (profile) {
                this.userProfile$.next(profile);
            }
        });
    }

}
