import {from, Observable, of, Subject, zip} from 'rxjs';
import {map} from 'rxjs/operators';

import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {AuthService} from 'ngx-auth';
import * as auth0 from 'auth0-js';

import {TokenStorage} from './token-storage.service';
import {AccessData} from './access-data';
import {Credential} from './credential';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {UtilsService} from "../../services/utils.service";

export class TokenHolder {
	access_token: string;
	id_token: string;
	refresh_token: string;
	expires_in: number;
}

@Injectable()
export class AuthenticationService implements AuthService {

	public onCredentialUpdated$: Subject<AccessData>;

	private auth0: auth0.WebAuth;

	constructor(
		private http: HttpClient,
		private tokenStorage: TokenStorage,
		private util: UtilsService,
		private router: Router
	) {
		this.onCredentialUpdated$ = new Subject();

		this.auth0 = new auth0.WebAuth({
			clientID: environment.clientId,
			domain: environment.domain,
			responseType: 'code',
			audience: 'mp-api',
			redirectUri: window.location.origin + '/callback',
			scope: 'openid profile mp:access offline_access',
		});

	}

	public handleAuthentication(tokenHolder: TokenHolder) {
		if (tokenHolder && tokenHolder.access_token && tokenHolder.id_token) {
			this.auth0.client.userInfo(tokenHolder.access_token, (err, profile) => {
				if (profile) {

					const roles = profile['https://mp.mutaforma.com/roles'];

					const accessData: AccessData = {
						accessToken: tokenHolder.access_token,
						idToken: tokenHolder.id_token,
						roles: roles,
						refreshToken: tokenHolder.refresh_token,
						expiresAt: (tokenHolder.expires_in * 1000) + new Date().getTime(),
						name: profile.name,
						picture: profile.picture
					};

					this.saveAccessData(accessData);
					this.router.navigate(['/']);

				} else {
					console.log('failed to get the profile info');
				}
			});
		}
	}

	public _handleAuthentication(): void {
		this.auth0.parseHash((err, authResult) => {
			if (authResult && authResult.accessToken && authResult.idToken) {

				console.log(authResult);


				console.log('access granted');

			} else if (err) {

            	console.log("error: {}", err);

            }
        });
    }

	/**
	 * Check, if user already authorized.
	 * @description Should return Observable with true or false values
	 * @returns {Observable<boolean>}
	 * @memberOf AuthService
	 */
	public isAuthorized(): Observable<boolean> {
        const accessToken$ = this.tokenStorage.getAccessToken().pipe(map(token => !!token));
        const expiration$ = this.tokenStorage.getExpiration().pipe(map(expiresAt => !!(new Date().getTime() < expiresAt)));
        // TODO: refactor this because zip is deprecated
        return zip(accessToken$, expiration$, (accessToken, expiration) => {
            return accessToken && expiration;
        });
    }

	/**
	 * Get access token
	 * @description Should return access token in Observable from e.g. localStorage
	 * @returns {Observable<string>}
	 */
	public getAccessToken(): Observable<string> {
		return this.tokenStorage.getAccessToken();
	}

	/**
	 * Get user roles
	 * @returns {Observable<any>}
	 */
	public getUserRoles(): Observable<any> {
		return this.tokenStorage.getUserRoles();
	}

	public getUserName(): Observable<any> {
		return this.tokenStorage.getUserName();
	}

	/**
	 * Function, that should perform refresh token verifyTokenRequest
	 * @description Should be successfully completed so interceptor
	 * can execute pending requests or retry original one
	 * @returns {Observable<any>}
	 */
	public refreshToken(): Observable<AccessData> {
		return of(null);
	}

	/**
	 * Function, checks response of failed request to determine,
	 * whether token be refreshed or not.
	 * @description Essentialy checks status
	 * @param {Response} response
	 * @returns {boolean}
	 */
	public refreshShouldHappen(response: HttpErrorResponse): boolean {
		return response.status === 401;
	}

	/**
	 * Verify that outgoing request is refresh-token,
	 * so interceptor won't intercept this request
	 * @param {string} url
	 * @returns {boolean}
	 */
	public verifyTokenRequest(url: string): boolean {
		return null;
	}

	/**
	 * Submit login request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	public login() {
        this.auth0.authorize();
	}

	/**
	 * Handle Http operation that failed.
	 * Let the app continue.
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	private handleError<T>(operation = 'operation', result?: any) {
		return (error: any): Observable<any> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// Let the app keep running by returning an empty result.
			return from(result);
		};
	}

	/**
	 * Logout
	 */
	public logout(refresh?: boolean): void {

		this.tokenStorage.clear();

        window.location.href = 'https://' + environment.domain + '/v2/logout?returnTo='
			+ window.location.origin + '&client_id=' + environment.clientId;
	}

    /**
     * Save access data in the storage
     * @private
     * @param {AccessData} data
     */
    private saveAccessData(accessData: AccessData) {
        if (typeof accessData !== 'undefined') {
            this.tokenStorage
                .setAccessToken(accessData.accessToken)
				.setIdToken(accessData.idToken)
                .setRefreshToken(accessData.refreshToken)
                .setExpiration(accessData.expiresAt)
				.setUserRoles(accessData.roles)
				.setUserName(accessData.name);

            this.onCredentialUpdated$.next(accessData);
        }
    }

}
