import { Component, OnInit } from '@angular/core';
import {DomainObject} from "../../models/_domain.model";

@Component({
  selector: 'gt-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export abstract class BaseComponent implements OnInit {

	abstract onInit();

	ngOnInit() {
		this.onInit()
	}

	domainObjectCompareFn(op1: DomainObject, op2: DomainObject) {
		if (!op1 || !op2 ) {
			return false;
		}
		return op1.id === op2.id;
	}

}
