import {_baseService} from "./_base.service";
import {HttpClient} from "@angular/common/http";

export abstract class _httpService extends _baseService {

	protected constructor(protected http: HttpClient) {
		super();
	}

}
