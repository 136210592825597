import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Operator} from "../../../../../model/operator";
import {Pool} from "../../../../../model/pool";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";

export class SelectionDidChange {
	selectedOperatorId: number
	selectedPoolId: number
	selectedDate: Date
}

@Component({
  selector: 'gt-selection-toolbar',
  templateUrl: './selection-toolbar.component.html',
  styleUrls: ['./selection-toolbar.component.scss']
})
export class SelectionToolbarComponent implements OnInit {

	@Input("operators") operators: Operator[]
	@Input("pools") pools: Pool[]

	@Input("selectedOperatorId") selectedOperatorId: number
	@Output("selectedOperatorIdDidChange") selectedOperatorIdChange:EventEmitter<number> = new EventEmitter<number>()

	@Input("selectedPoolId") selectedPoolId: number
	@Output("selectedPoolIdDidChange") selectedPoolIdChange:EventEmitter<number> = new EventEmitter<number>()

	@Input("selectedDate") selectedDate: Date
	@Output("selectedDateDidChange") selectedDateChange: EventEmitter<Date> = new EventEmitter<Date>()

	@Output("selectionDidChange") selectionDidChange: EventEmitter<SelectionDidChange> = new EventEmitter<SelectionDidChange>()

	selectedOperator: Operator
	selectedPool: Pool


  constructor() { }

  ngOnInit() {
  }

  emitChangeEvent(date: Date, operator: Operator, pool: Pool) {
		let event = new SelectionDidChange()
		event.selectedDate = date
		event.selectedOperatorId = this.selectedOperator ? this.selectedOperator.id : -1
		event.selectedPoolId = this.selectedPool ? this.selectedPool.id : -1
		this.selectionDidChange.emit(event)
	}

	operatorDisplayFn(operator: Operator): string | undefined {
		return operator ? operator.lastName + ' ' + operator.firstName : undefined
	}

	poolDisplayFn(pool: Pool): string | undefined {
		return pool ? pool.name : undefined
	}

	dateDidChange($event: MatDatepickerInputEvent<Date>) {
		this.emitChangeEvent(this.selectedDate, this.selectedOperator, this.selectedPool)
	}

	didSelectOperator() {
		this.selectedPool = null
		this.emitChangeEvent(this.selectedDate, this.selectedOperator, this.selectedPool)

	}

	didSelectPool() {
		this.selectedOperator = null
		this.emitChangeEvent(this.selectedDate, this.selectedOperator, this.selectedPool)
	}
}
