import {DomainObject} from "../core/_base/crud/models/_domain.model";
import {BusinessName} from "./business-name";
import {Signboard} from "./signboard";
import {ServiceType} from "./service-type";
import {Store} from "./store";

export enum DayOfWeek {
	monday,
	tuesday,
	wednesday,
	thursday,
	friday,
	saturday,
	sunday,
	holiday
}

export class BusinessOrder extends DomainObject {
	name: string
	businessName: BusinessName
	signBoard: Signboard
	note: string
	rows: BusinessOrderRow[] = []

}

export class BusinessOrderRow extends DomainObject {
	dutyCadence: string
	weeklyItem: WeeklyItem
	spots: SpotItem[] = []
	store: Store;
}

export class WeeklyItem extends DomainObject {

	constructor() {
		super();
		this.weekDays = []
		this.weekDays.push(
			new WeekDay(DayOfWeek.monday),
			new WeekDay(DayOfWeek.tuesday),
			new WeekDay(DayOfWeek.wednesday),
			new WeekDay(DayOfWeek.thursday),
			new WeekDay(DayOfWeek.friday),
			new WeekDay(DayOfWeek.saturday),
			new WeekDay(DayOfWeek.sunday),
			new WeekDay(DayOfWeek.holiday)
		)
	}

	weekDays: WeekDay[]
}

export class SpotItem extends DomainObject {
	date: Date
	location: Location
	serviceType: ServiceType
	operators: number
	startTime: Date
	endTime: Date
}

export class WeekDay extends DomainObject {

	constructor(weekOfDay: DayOfWeek) {
		super();
		this.dayOfWeek = weekOfDay;
	}

	dayOfWeek: DayOfWeek
	weekDayRows: WeekDayRow[] = []

}

export class WeekDayRow extends DomainObject {
	location: Location
	serviceType: ServiceType
	requiredOperators: number
	startTime: Date
	endTime: Date
}


