import { BusinessName } from './business-name'
import { Store } from './store'
import {DomainObject} from "../core/_base/crud/models/_domain.model";

export class Signboard extends DomainObject {
    name: string
    businessName : BusinessName
    stores_ids: string[] = []
    _stores: Store[] = []
}
