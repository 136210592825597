import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'h24',
  templateUrl: './h24.component.html'
})
export class H24Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
