import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BackendService} from "../core/_base/crud/services/_backend.service";

@Injectable()
export class OAUTHService extends BackendService {

    constructor(http: HttpClient) {
        super(http, 'oauth');
    }

    requestToken(authorization: Authorization): Observable<any> {
        return this.http.post(this.basepath() + '/requestToken', authorization);
    }

    refreshToken(refreshToken: string): Observable<any> {
        return this.http.post(this.basepath() + '/refreshToken', refreshToken);
    }

}

export class Authorization {

    code: string;
    redirectUri: string;

    constructor(code: string, redirectUri: string) {
        this.code = code;
        this.redirectUri = redirectUri;
    }

}
