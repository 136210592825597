import {BackendService} from "../core/_base/crud/services/_backend.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Operator} from "../model/operator";
import {DatePipe} from "@angular/common";
import {Shift} from "../model/shift";

export class ExcelView {
	startDate: Date
	endDate: Date
	weekOfYear: number
	header: ExcelRow
	excelRows: ExcelRow[]
}

export class ExcelRow {
	operator: Operator
	days: ExcelDay[]
}

export class ExcelDay {
	position: number
	dayOfWeek: string
	authorityDate: Date
	shifts: Shift[]
}

@Injectable()
export class ExcelService extends BackendService {

	private datePipe = new DatePipe("en-US")

	constructor(http: HttpClient) {
		super(http, 'excel');
	}

	excelForDate(authorityDate: Date, operatorId: number, poolId: number): Observable<ExcelView> {

		var params = new HttpParams()
		params = params.set('operatorId', String(operatorId));
		params = params.set('poolId', String(poolId));

		let dateString = this.datePipe.transform(authorityDate, 'yyyy-MM-dd');
		return this.http.get<ExcelView>(this.basepath() + '/for-date/' + dateString, {params: params})
	}

}
